import api from "./index"

/**
 * Get random Avatars
 *
 * @return {*}
 */
export const getRandomAvatars = (params, includes = []) => {
  return api 
    .request(`admin/avatars`)
    .withParams(params)
    .withIncludes(includes)
    .get()
}

/**
 * Delete random Avatars
 *
 * @return id
 */
export const deleteRandomAvatars = id => {
  return api.request(`admin/avatar/${id}/delete`).delete()
}
